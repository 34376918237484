import * as React from "react";

const NotFoundPage: React.FC = () => {
  if (typeof window !== "undefined") {
    window.location = "/";
  }

  return null;
};

export default NotFoundPage;
